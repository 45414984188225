<template>
  <div>
    <Breadcrumbs />
    <v-simple-table style="width:100%;overflow:auto">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              {{ $t("Icon") }}
            </th>
            <th class="text-left">
              {{ $t("Application Name") }}
            </th>
            <th class="text-left">
              {{ $t("Application Adapter") }}
            </th>
            <th class="text-left" style="width:30%">
              {{ $t("Description") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.type" class="text-left">
            <td class="pl-4">
              <v-img
                :src="require('@/assets/application/' + item.type + '.png')"
                max-width="40"
              ></v-img>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td style="width:200px">{{ item.description }}</td>
            <td class="text-center">
              <v-btn
                elevation="0"
                color="primary"
                outlined
                @click.stop="show_sheet(item)"
              >
                {{ $t("application.Create Application") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component :is="sheet_editor" type="create" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import JwtEditor from "@/views/application/editor/Jwt";
import CasEditor from "@/views/application/editor/Cas";
import Saml2Editor from "@/views/application/editor/Saml2";
import AliyunRoleEditor from "@/views/application/editor/AliyunRole";
import AliyunUserEditor from "@/views/application/editor/AliyunUser";
import CarsiEditor from "@/views/application/editor/Carsi";
import WebVpnEditor from "@/views/application/editor/WebVpn";
import FormFillEditor from "@/views/application/editor/FormFill";
import Oauth2Editor from "@/views/application/editor/Oauth2";
import OidcEditor from "@/views/application/editor/Oidc";
import LdapEditor from "@/views/application/editor/Ldap";
import RadiusEditor from "@/views/application/editor/Radius";

const EDITORS = {
  JWT: JwtEditor,
  CAS: CasEditor,
  SAML2: Saml2Editor,
  ALIYUN_RAM_ROLE: AliyunRoleEditor,
  ALIYUN_RAM_USER: AliyunUserEditor,
  CARSI: CarsiEditor,
  WebVPN: WebVpnEditor,
  FORM_FILL: FormFillEditor,
  OAUTH2: Oauth2Editor,
  OIDC: OidcEditor,
  LDAP: LdapEditor,
  RADIUS: RadiusEditor
};

export default {
  name: "CreateApplication",
  data() {
    return {
      show: true,
      sheet_editor: null,
      sources: [
        {
          name: "CAS应用",
          type: "CAS",
          description:
            "CAS（Central Authentication Service，集中式认证服务，版本 2.0）是一种基于挑战、应答的开源单点登录协议。在集成客户端和服务端之间网络通畅的情况下广泛在企业中使用，有集成简便，扩展性强的优点。"
        },
        {
          name: "SAML2应用",
          type: "SAML2",
          description:
            "SAML（Security Assertion Markup Language，安全断言标记语言，版本 2.0）基于 XML 协议，使用包含断言（Assertion）的安全令牌，在授权方（IAM）和消费方（应用）之间传递身份信息，实现基于网络跨域的单点登录。SAML 协议是成熟的认证协议，在国内外的公有云和私有云中有非常广泛的运用。"
        },
        {
          name: "阿里云RAM-用户SSO",
          type: "ALIYUN_RAM_USER",
          description:
            "基于 SAML 协议，实现由 IAM 单点登录到阿里云控制台；使用该模板，需要在RAM中为每个用户单独创建RAM子账户，IAM账户和RAM子账户通过映射实现单点登录到RAM。"
        },
        {
          name: "阿里云RAM-角色SSO",
          type: "ALIYUN_RAM_ROLE",
          description:
            "基于 SAML 协议，实现由 IAM 单点登录到阿里云控制台；使用该模板，需要RAM中创建RAM角色，不需要为每个用户单独创建RAM子账户，IAM账户和RAM角色通过映射实现单点登录到RAM。"
        },
        {
          name: "CARSI应用",
          type: "CARSI",
          description:
            "基于 CARSI 认证，使用学校统一认证账户就可以直接登录访问。"
        },
        // {
        //   name: "OAuth2应用",
        //   type: "OAUTH2",
        //   description: "OAuth 是一个开放的资源授权协议，应用可以通过 OAuth 获取到令牌 access_token，并携带令牌来服务端请求用户资源。应用可以使用 OAuth 应用模板来实现统一身份管理。"
        // },
        // {
        //   name: "OIDC应用",
        //   type: "OIDC",
        //   description: "使用 OIDC 应用"
        // },
        // {
        //   name: "LDAP应用",
        //   type: "LDAP",
        //   description: "使用 LDAP 应用"
        // },
        {
          name: "JWT应用",
          type: "JWT",
          description:
            "JWT（JSON Web Token）是在网络应用环境声明的一种基于 JSON 的开放标准。IAM 使用 JWT 进行分布式站点的单点登录 （SSO）。JWT 单点登录基于非对称加密，由 IAM 将用户状态和信息使用私钥加密，传递给应用后，应用使用公钥解密并进行验证。使用场景非常广泛，集成简单。"
        },
        // {
        //   name: "WebEXP应用",
        //   type: "WebEXP",
        //   description: "使用 WebEXP 应用"
        // },
        {
          name: "FORM_FILL应用",
          type: "FORM_FILL",
          description:
            "表单代填可以模拟用户在登录页输入用户名和密码，再通过表单提交的一种登录方式。应用的账号密码在 IAM 中使用 AES256 加密算法本地加密存储。很多旧系统、不支持标准认证协议的系统或不支持改造的系统可以使用表单代填实现统一身份管理。表单中有图片验证码、CSRF token、动态参数的场景不适用。"
        },
        // {
        //   name: "RADIUS应用",
        //   type: "RADIUS",
        //   description: "使用 RADIUS 应用"
        // },
        {
          name: "WebVPN",
          type: "WebVPN",
          description:
            "WebVPN提供基于web的内网应用访问控制，允许授权用户访问只对内网开放的web应用，实现类似VPN（虚拟专用网）的功能。"
        }
      ]
    };
  },
  methods: {
    ...mapMutations(["change_breadcrumbs"]),
    show_sheet(item) {
      this.sheet_editor = EDITORS[item.type];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  components: {
    Breadcrumbs,
    JwtEditor,
    CasEditor,
    Saml2Editor,
    AliyunRoleEditor,
    AliyunUserEditor,
    CarsiEditor,
    WebVpnEditor,
    FormFillEditor,
    Oauth2Editor,
    OidcEditor,
    LdapEditor,
    RadiusEditor
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
